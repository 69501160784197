<template>
  <v-app-bar
    app
    flat
  >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="toggleDrawer"
    />

    <v-container class="mx-auto py-0">
      <v-row align="center">
        <router-link to="/">
          <v-img :src="require('@/assets/logo.png')" class="logo"></v-img>
        </router-link>
        <v-btn
          class="hidden-sm-and-down font-weight-bold pa-2"
          x-large
          color="grey darken-3"
          text
          @click="$router.push('/')"
        >
          Peppol Directory [Test]
        </v-btn>

        <v-menu
          v-for="item in items"
          :key="item.title"
          :data="item"
          open-on-hover
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="hidden-sm-and-down font-weight-bold pa-2"
              text
              color="grey darken-2"
              x-large
              v-on="on"
            >
              {{ item.title }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="subitem in item.subItems"
              :key="subitem.title"
              :to="subitem.to"
              :href="subitem.link"
              :target="subitem.target"
            >
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          class="hidden-sm-and-down font-weight-bold pa-2"
          text
          color="grey darken-2"
          x-large
          @click="$router.push('about')"
        >
          About
        </v-btn>

        <v-btn
          v-if="isLoggedin"
          class="hidden-sm-and-down font-weight-bold pa-2"
          x-large
          color="grey darken-2"
          text
          @click="$router.push('admin')"
        >
          Admin
        </v-btn>
        <div style="margin-left: auto;margin-right: 0;">
          <v-btn
            text
            color="grey darken-2"
            x-large
            v-if="isLoggedin"
            class="hidden-sm-and-down font-weight-bold pa-2"
            @click="logout"
          >
            Logout
          </v-btn>
          <v-btn
            text
            color="grey darken-2"
            x-large
            v-if="!isLoggedin"
            class="hidden-sm-and-down font-weight-bold"
            @click="$router.push('login')"
          >
            Login
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
// Utilities
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'CoreAppBar',
  data() {
    return {
      items: [
        {
          title: 'Documentation',
          subItems: [
            {
              title: 'Introduction',
              to: 'document-introduction',
            },
            {
              title: 'How To Use It',
              to: 'document-how-to-use',
            },
            {
              title: 'REST Api',
              to: 'document-rest-api',
            },
            {
              title: 'Export Data',
              to: 'document-export-data',
            },
            {
              title: 'Specification v1.1.1 (PDF)',
              link: 'files/PEPPOL-EDN-Directory-1.1.1-2020-10-15.pdf',
            },
            {
              title: 'Guide for SMP Providers (PDF)',
              link: 'files/OpenPEPPOL%20Directory%20for%20SMP%20providers%202016-12-05.pdf',
            },
          ],
        },
        {
          title: 'Support',
          subItems: [
            {
              title: 'Peppol Service Desk',
              link: 'https://openpeppol.atlassian.net/servicedesk/customer/portal/1',
              target: '_blank',
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['links', 'isLoggedin']),
  },

  methods: {
    ...mapMutations(['toggleDrawer', 'setAuthUser']),
    onClick(e, item) {
      // e.stopPropagation()

      if (item.to || !item.href) return
      this.$vuetify.goTo(item.href)
    },
    logout() {
      this.setAuthUser(null)
      this.$router.push('login')
    },
  },
}
</script>

<style>
.v-btn {
  font-family: Roboto, sans-serif;
  text-transform: capitalize;
  font-size: 1.5rem;
}
</style>
